import React from 'react';
import './LogEntry.css';

function LogEntry({ log, LogMessageComponent }) {
    return (
        <div className="logpage-log-line">
            <span className="logpage-log-date">
                {`[${new Date(log.timestamp * 1000).toLocaleString()}]`}
            </span>
            <LogMessageComponent log={log} />
        </div>
    );
}

export default LogEntry;