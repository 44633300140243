import React, { useState, memo } from "react";
import Stats from "./Stats";
import Shops from "./Shops";
import Navigation from "../components/Navigation";
import "./AdminPage.css";

const MemoizedStats = memo(Stats);
const MemoizedShops = memo(Shops);

function AdminPage() {
  const [activePage, setActivePage] = useState("stats");

  const buttons = [
    {
      label: "Статистика",
      className: activePage === "stats" ? "btn-primary" : "btn-outline-primary",
      onClick: () => setActivePage("stats"),
    },
    {
      label: "Магазины",
      className: activePage === "shops" ? "btn-primary" : "btn-outline-primary",
      onClick: () => setActivePage("shops"),
    },
    {
      label: "Карты",
      className: activePage === "cards" ? "btn-primary" : "btn-outline-primary",
      onClick: () => console.log("Cards"),
    },
    // Add more buttons here if needed
  ];

  return (
    <div className="adminpage-App container mt-4">
      <div className="adminpage-main_center mx-auto">
        <Navigation buttons={buttons} />
        <div className="adminpage-content">
          <div style={{ display: activePage === "stats" ? "block" : "none" }}>
            <MemoizedStats />
          </div>
          <div style={{ display: activePage === "shops" ? "block" : "none" }}>
            <MemoizedShops />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
