import React from 'react';

function LogMessage({ log, openSurvey, openCard }) {
    if (log.surveyType === 'TOKEN') {
        return (
            <>
                {` Карта `}
                <button 
                    className="transparent-button logpage-highlight logpage-card-link"
                    onClick={() => openCard(log.cardNum)}
                >
                    {log.cardNum}
                </button>
                {` прошла опрос `}
                <button 
                    className="transparent-button logpage-highlight logpage-survey-link"
                    onClick={() => openSurvey(log.surveyId)}
                >
                    {log.surveyId}
                </button>
            </>
        );
    } else {
        return (
            <>
                {` Опрос по почте `}
                <button 
                    className="transparent-button logpage-highlight logpage-survey-link"
                    onClick={() => openSurvey(log.surveyId)}
                >
                    {log.surveyId}
                </button>
            </>
        );
    }
}

export default LogMessage;