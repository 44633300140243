import React, { useState, useEffect } from "react";
import "./AddStorePopup.css";

function AddStorePopup({ onClose, onAdd, onEdit, editingStore }) {
  const [storeId, setStoreId] = useState("");
  const [address, setAddress] = useState("");
  const [chatId, setChatId] = useState("");

  useEffect(() => {
    if (editingStore) {
      setStoreId(editingStore.storeId);
      setAddress(editingStore.address);
      setChatId(editingStore.chatId);
    } else {
      // Сброс полей при открытии popup для добавления нового магазина
      setStoreId("");
      setAddress("");
      setChatId("");
    }
  }, [editingStore]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const storeData = {
      storeId,
      address,
      chatId,
    };

    if (editingStore) {
      onEdit({ ...storeData, storeUuid: editingStore.storeUuid });
    } else {
      onAdd(storeData);
    }
    onClose();
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>
          {editingStore ? "Редактировать магазин" : "Добавить новый магазин"}
        </h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="storeId">Store ID:</label>
            <input
              type="text"
              id="storeId"
              value={storeId}
              onChange={(e) => setStoreId(e.target.value)}
              required
              disabled={editingStore} // Делаем поле неактивным при редактировании
            />
          </div>
          <div>
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="chatId">Chat ID:</label>
            <input
              type="text"
              id="chatId"
              value={chatId}
              onChange={(e) => setChatId(e.target.value)}
              required
            />
          </div>
          <div className="popup-buttons">
            <button type="submit">
              {editingStore ? "Обновить" : "Добавить"}
            </button>
            <button type="button" onClick={onClose}>
              Отмена
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddStorePopup;
