// Stats.js
import React, { useState, useEffect } from 'react';
import SurveyChart from '../components/SurveyChart';
import LogEntry from '../components/LogEntry';
import LogMessageWithAddress from '../components/LogMessageWithAddress';
import './Stats.css';

function Stats() {
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState(null);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        fetch('http://127.0.0.1:8081/api/allSurveys')
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    setError(data.message);
                } else if (Array.isArray(data) && data.length > 0) {
                    setLogs(data);
                    prepareChartData(data);
                } else {
                    setError('Нет доступных логов');
                }
            })
            .catch(error => {
                console.error('Error fetching logs:', error);
                setError('Произошла ошибка при загрузке данных');
            });
    }, []);

    const prepareChartData = (logs) => {
        const last30Days = [...Array(30)].map((_, i) => {
            const d = new Date();
            d.setDate(d.getDate() - i);
            return d.toLocaleDateString();
        }).reverse();

        const surveyCounts = last30Days.map(date => {
            return logs.filter(log => {
                const logDate = new Date(log.timestamp * 1000);
                return logDate.toLocaleDateString() === date;
            }).length;
        });

        setChartData({
            labels: last30Days,
            datasets: [
                {
                    label: 'Количество опросов',
                    data: surveyCounts,
                    fill: false,
                    borderColor: 'rgb(66, 80, 192)',
                    tension: 0.1
                }
            ]
        });
    };

    if (error) {
        return (
            <div className="stats-App container">
                <div className="stats-main_center mx-auto">
                    <div className="stats-content">
                        <h2>Ошибка</h2>
                        <p>{error}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="stats-App container">

                <div className="stats-stats mb-4">
                    <div className="row">
                        <div className="col text-center mb-2">
                            <div className="stat-item bg-light p-2 rounded">Всего опросов за день: 0</div>
                        </div>
                        <div className="col text-center mb-2">
                            <div className="stat-item bg-light p-2 rounded">Всего опросов за месяц: 0</div>
                        </div>
                        <div className="col text-center mb-2">
                            <div className="stat-item bg-light p-2 rounded">Всего карт: 0</div>
                        </div>
                        <div className="col text-center mb-2">
                            <div className="stat-item bg-light p-2 rounded">Свободных карт: 0</div>
                        </div>
                        <div className="col text-center mb-2">
                            <div className="stat-item bg-light p-2 rounded">Всего магазинов: 0</div>
                        </div>
                    </div>
                </div>
                <div className="stats-content">
                    <h2>Статистика всех опросов</h2>
                    {chartData && <SurveyChart chartData={chartData} />}
                    {logs.length > 0 ? (
                        <div className="stats-log-entry">
                            {logs.map((log, index) => (
                                <LogEntry 
                                    key={log.id || `log-${index}`} 
                                    log={log} 
                                    LogMessageComponent={(props) => <LogMessageWithAddress {...props} />}
                                />
                            ))}
                        </div>
                    ) : (
                        <p>Нет доступных логов</p>
                    )}
                </div>
            </div>
        
    );
}

export default Stats;