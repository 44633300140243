import React, { useState, useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import AddStorePopup from "./AddStorePopup";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import Navigation from "../components/Navigation"; // Import the new Navigation component
import "./Shops.css";

function Shops() {
  const [stores, setStores] = useState([]);
  const [error, setError] = useState(null);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] =
    useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [editingStore, setEditingStore] = useState(null);

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = () => {
    fetch("http://127.0.0.1:8081/api/allStores")
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setError(data.message);
        } else {
          setStores(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching stores:", error);
        setError("Произошла ошибка при загрузке данных");
      });
  };

  useEffect(() => {
    if (isAddPopupOpen || isConfirmDeletePopupOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isAddPopupOpen, isConfirmDeletePopupOpen]);

  const handleDeleteClick = () => {
    if (Object.keys(rowSelection).length > 0) {
      setIsConfirmDeletePopupOpen(true);
    }
  };

  const handleConfirmDelete = () => {
    const selectedStoreIds = Object.keys(rowSelection);
    selectedStoreIds.forEach((storeUuid) => {
      fetch(`http://127.0.0.1:8081/api/deleteStore/${storeUuid}`, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setError(data.message);
          } else {
            setStores(stores.filter((s) => s.storeUuid !== storeUuid));
          }
        })
        .catch((error) => {
          console.error("Error deleting store:", error);
          setError("Произошла ошибка при удалении магазина");
        });
    });
    setIsConfirmDeletePopupOpen(false);
    setRowSelection({});
  };

  const handleAdd = (newStore) => {
    setStores([...stores, newStore]);
    setIsAddPopupOpen(false);
  };

  const handleEdit = (updatedStore) => {
    fetch(`http://127.0.0.1:8081/api/updateStore/${updatedStore.storeUuid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedStore),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setError(data.message);
        } else {
          setStores(
            stores.map((store) =>
              store.storeUuid === updatedStore.storeUuid ? data : store
            )
          );
          setEditingStore(null);
        }
      })
      .catch((error) => {
        console.error("Error updating store:", error);
        setError("Произошла ошибка при обновлении магазина");
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "storeId",
        header: "ID",
        size: 1,
      },
      {
        accessorKey: "storeUuid",
        header: "UUID",
      },
      {
        accessorKey: "address",
        header: "Address",
      },
      {
        accessorKey: "chatId",
        header: "Chat ID",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: stores,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getRowId: (row) => row.storeUuid,
    enableDensityToggle: false,
    initialState: { density: "compact" },
    enableStickyHeader: true,
    paginationDisplayMode: "pages",
    muiTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
    positionToolbarAlertBanner: "bottom",
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
  });

  const handleEditClick = () => {
    const selectedStoreIds = Object.keys(rowSelection);
    if (selectedStoreIds.length === 1) {
      const storeToEdit = stores.find(
        (store) => store.storeUuid === selectedStoreIds[0]
      );
      setEditingStore(storeToEdit);
      setIsAddPopupOpen(true);
    } else {
      setError("Пожалуйста, выберите только один магазин для редактирования");
    }
  };

  const buttons = [
    {
      label: "Добавить Магазин",
      className: "btn-outline-primary",
      onClick: () => {
        setEditingStore(null);
        setIsAddPopupOpen(true);
      },
    },
    {
      label: "Редактировать",
      className: "btn-outline-primary",
      onClick: handleEditClick,
    },
    {
      label: "Удалить Выбранные",
      className: "btn-outline-primary",
      onClick: handleDeleteClick,
    },
  ];

  if (error) {
    return (
      <div className="shops-App container">
        <div className="shops-main_center mx-auto">
          <h2>Ошибка</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="shops-App container">
      <div className="shops-main_center mx-auto">
        <Navigation buttons={buttons} />
        {isAddPopupOpen && (
          <AddStorePopup
            onClose={() => {
              setIsAddPopupOpen(false);
              setEditingStore(null);
            }}
            onAdd={handleAdd}
            onEdit={handleEdit}
            editingStore={editingStore}
          />
        )}
        {isConfirmDeletePopupOpen && (
          <ConfirmDeletePopup
            onConfirm={handleConfirmDelete}
            onCancel={() => setIsConfirmDeletePopupOpen(false)}
          />
        )}
        <div className="shops-content">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div>
  );
}

export default Shops;
