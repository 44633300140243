import React from 'react';

const openSurvey = (surveyId) => {
    window.open(`https://o.5ka.ru/${surveyId}?src=push`, '_blank');
};

const openStore = (storeUuid) => {
    const currentHost = window.location.hostname;
    window.open(`http://${currentHost}/log/${storeUuid}`, '_blank');
};

const openCard = (cardNum) => {
    const currentHost = window.location.hostname;
    window.open(`http://${currentHost}/${cardNum}`, '_blank');
};

function LogMessageWithAddress({ log }) {
    console.log(log.storeUuid);
    return (
        <>
            {log.surveyType === 'TOKEN' ? (
                <>
                    {` Карта `}
                    <button 
                        className="transparent-button logpage-highlight logpage-card-link"
                        onClick={() => openCard(log.cardNum)}
                    >
                        {log.cardNum}
                    </button>
                    {` прошла опрос `}
                    <button 
                        className="transparent-button logpage-highlight logpage-survey-link"
                        onClick={() => openSurvey(log.surveyId)}
                    >
                        {log.surveyId}
                    </button>
                </>
            ) : (
                <>
                    {` Опрос по почте `}
                    <button 
                        className="transparent-button logpage-highlight logpage-survey-link"
                        onClick={() => openSurvey(log.surveyId)}
                    >
                        {log.surveyId}
                    </button>
                    <button 
                        className="transparent-button logpage-highlight logpage-survey-link"
                        onClick={() => openSurvey(log.surveyId)}
                    >
                        {log.surveyId}
                    </button>
                    {` `}
                    <button 
                        className="transparent-button logpage-highlight logpage-store-link"
                        onClick={() => openStore(log.storeUuid)}
                    >
                        {` (${log.address})`}
                    </button>
                </>
            )}
            {/* Add a button to open the store */}

        </>
    );
}

export default LogMessageWithAddress;