import React from "react";
import "./Navigation.css"; // Import the new CSS file

function Navigation({ buttons }) {
  return (
    <nav className="adminpage-navigation">
      <div className="nav-items">
        {buttons.map((button, index) => (
          <button
            key={index} // Use index if button.page is not applicable
            className={`nav-item btn ${button.className}`}
            onClick={button.onClick} // Use the button's own onClick handler
          >
            {button.label}
          </button>
        ))}
      </div>
    </nav>
  );
}

export default Navigation;
