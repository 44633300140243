import React from "react";
import "./ConfirmDeletePopup.css";

function ConfirmDeletePopup({ onConfirm, onCancel }) {
  return (
    <div className="confirm-popup-overlay">
      <div className="confirm-popup-content">
        <h2>Подтверждение удаления</h2>
        <p>Вы действительно хотите удалить выбранные магазины?</p>
        <div className="confirm-popup-buttons">
          <button onClick={onConfirm} className="confirm-button">
            Да, удалить
          </button>
          <button onClick={onCancel} className="cancel-button">
            Отмена
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeletePopup;
