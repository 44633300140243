import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SurveyChart from '../components/SurveyChart';
import LogEntry from '../components/LogEntry';
import LogMessage from '../components/LogMessage';
import './LogPage.css';

function LogPage() {
    const { another_id } = useParams();
    const [logs, setLogs] = useState([]);
    const [storeInfo, setStoreInfo] = useState({ address: '', uuid: '' });
    const [error, setError] = useState(null);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (another_id) {
            fetch(`http://127.0.0.1:8081/api/storeSurveys/${another_id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.message) {
                        setError(data.message);
                    } else if (Array.isArray(data) && data.length > 0) {
                        setLogs(data);
                        setStoreInfo({
                            address: data[0].address,
                            uuid: data[0].storeUuid
                        });
                        prepareChartData(data);
                    } else {
                        setError('Нет доступных логов для этого магазина');
                    }
                })
                .catch(error => {
                    console.error('Error fetching logs:', error);
                    setError('Произошла ошибка при загрузке данных');
                });
        }
    }, [another_id]);

    const prepareChartData = (logs) => {
        const last30Days = [...Array(30)].map((_, i) => {
            const d = new Date();
            d.setDate(d.getDate() - i);
            return d.toLocaleDateString();
        }).reverse();

        const surveyCounts = last30Days.map(date => {
            return logs.filter(log => {
                const logDate = new Date(log.timestamp * 1000);
                return logDate.toLocaleDateString() === date;
            }).length;
        });

        setChartData({
            labels: last30Days,
            datasets: [
                {
                    label: 'Количество опросов',
                    data: surveyCounts,
                    fill: false,
                    borderColor: 'rgb(66, 80, 192)',
                    tension: 0.1
                }
            ]
        });
    };

    const openSurvey = (surveyId) => {
        window.open(`https://o.5ka.ru/${surveyId}?src=push`, '_blank');
    };
    const openCard = (cardNum) => {
        const currentHost = window.location.hostname;
        window.open(`http://${currentHost}/${cardNum}`, '_blank');
    };

    if (error) {
        return (
            <div className="logpage-App container">
                <div className="logpage-main_center mx-auto">
                    <div className="logpage-content">
                        <h2>Ошибка</h2>
                        <p>{error}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="logpage-App container">
            <div className="logpage-main_center mx-auto">
                <div className="logpage-content">
                    <h2>Логи для магазина: {storeInfo.address} (UUID: {storeInfo.uuid})</h2>
                    {chartData && <SurveyChart chartData={chartData} />}
                    {logs.length > 0 ? (
                        <div className="logpage-log-entry">
                            {logs.map((log, index) => (
                                <LogEntry 
                                    key={log.id || `log-${index}`} 
                                    log={log} 
                                    LogMessageComponent={(props) => <LogMessage {...props} openSurvey={openSurvey} openCard={openCard} />}
                                />
                            ))}
                        </div>
                    ) : (
                        <p>Нет доступных логов</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LogPage;